import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import FunctionsPageTemplate from '../components/Functions/FunctionsPageTemplate';
import MainSection from '../components/Functions/MainSection';
import main from '../assets/images/functions/profiles/main.png';
import main2x from '../assets/images/functions/profiles/main_2x.png';
import headLeft from '../assets/images/functions/profiles/head_left.png';
import headRight from '../assets/images/functions/profiles/head_right.png';
import HeadSection from '../components/Functions/HeadSection';
import PossibilitiesSection from '../components/Functions/PossibilitiesSection';
import first from '../assets/images/functions/profiles/1.png';
import first2x from '../assets/images/functions/profiles/1_2x.png';
import second from '../assets/images/functions/profiles/2.png';
import second2x from '../assets/images/functions/profiles/2_2x.png';
import third from '../assets/images/functions/profiles/3.png';
import third2x from '../assets/images/functions/profiles/3_2x.png';
import fourth from '../assets/images/functions/profiles/4.png';
import fourth2x from '../assets/images/functions/profiles/4_2x.png';
import fifth from '../assets/images/functions/profiles/5.png';
import fifth2x from '../assets/images/functions/profiles/5_2x.png';
import ImageContainer from '../components/ImageContainer/ImageContainer';
import BlueSpan from '../components/common/typography/BlueSpan';

const faqs = [
  {
    question: 'How do I create templates with necessary settings?',
    answer:
      'Go to Templates and click on Create a template. Add the template and profile names, and configure it as needed.',
  },
  {
    question: 'How do I add extensions?',
    answer:
      'In order to do this, you need to turn on extension saving in the profile settings, activate the necessary profile, and add the extensions you need through the Chrome Web Store.',
  },
].map((item) => ({
  question: <Trans>{item.question}</Trans>,
  answer: <Trans>{item.answer}</Trans>,
}));

const cardsInfo = [
  {
    title: 'Export and import profiles',
    description:
      'Octo Browser users can export and import profiles in the .octo format.',
    icon: <ImageContainer src={first} srcSet={{ _2x: first2x }} />,
  },
  {
    title: 'Control the fingerprint',
    description:
      'You can adjust every parameter of the browser fingerprint: there are more than 50 of them!',
    icon: <ImageContainer src={second} srcSet={{ _2x: second2x }} />,
  },
  {
    title: 'Cloud storage',
    description:
      'Keep all your data in a completely safe encrypted cloud storage.',
    icon: <ImageContainer src={third} srcSet={{ _2x: third2x }} />,
  },
  {
    title: 'Profile history',
    description: 'Keep track of 100 last profile actions at any time.',
    icon: <ImageContainer src={fourth} srcSet={{ _2x: fourth2x }} />,
  },
  {
    title: 'Paste as human typing',
    description:
      'Use the paste as a human typing feature to get past anti-fraud systems.',
    icon: <ImageContainer src={fifth} srcSet={{ _2x: fifth2x }} />,
  },
];

const ProfilesPage = (): JSX.Element => (
  <FunctionsPageTemplate faq={faqs}>
    <HeadSection
      description="A well set-up profile can completely protect your accounts from detection or bans."
      leftBgImage={headLeft}
      rightBgImage={headRight}
      title="Profiles"
    />
    <MainSection
      description="Create, clone, export, and transfer your profiles without limitations. Create a quick profile with a unique browser fingerprint in one click and start working."
      image={main}
      image2x={main2x}
      title={
        <Trans i18nKey="Create secure profiles">
          Create <BlueSpan>secure profiles</BlueSpan>
        </Trans>
      }
    />
    <PossibilitiesSection
      cards={cardsInfo}
      title={
        <Trans i18nKey="Profile features">
          Profile <BlueSpan>features</BlueSpan>
        </Trans>
      }
    />
  </FunctionsPageTemplate>
);

export default ProfilesPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["profilesPage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
